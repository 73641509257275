import { CardContent, Grid, Typography } from '@mui/material';
import PageLoader from '../../../Components/Spinner/PageLoader';
import moment from 'moment';
import { ICompanyDetails } from '../../../rest/types';
import ModalsWrapper from '../../../Components/Modals/ModalsWrapper';
import PdfAndImageView from '../../../Components/pdfAndImageView/PdfAndImageView';
import { useState } from 'react';
import { CommonTypoGraphy } from '../../../Components/pdfAndImageView/CommonTypoGraphy';

type IProps = {
  data: ICompanyDetails;
  loading: boolean;
};

const CardCompanyInformation = ({ data, loading }: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>('');

  return (
    <>
      {!loading ? (
        <CardContent>
          <Grid
            container
            pt={2}
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Company name:
              </Typography>
              {data.member_company_name}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Company type:
              </Typography>
              {data.member_company_type}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Company stablish date:
              </Typography>
              {moment(data.member_company_stablished_date).format('Do-MM-YYYY')}
            </Grid>
            {data.member_company_verified_date && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Company verified date:
                </Typography>
                {moment(data.member_company_verified_date).format('Do-MM-YYYY')}
              </Grid>
            )}
            {data.member_company_website && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Company website:
                </Typography>
                <a
                  href={`https://${data.member_company_website}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {data.member_company_website}
                </a>
              </Grid>
            )}
            {data.member_company_logo && (
              <Grid item xs={2} sm={4} md={4}>
                <CommonTypoGraphy
                  name='View company logo'
                  onClick={() => {
                    setOpen(true);
                    setImageFile(data.member_company_logo);
                  }}
                />
              </Grid>
            )}
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Mobile Number:
              </Typography>
              {data.phone}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Telephone:
              </Typography>
              {data.cellphone}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Email:
              </Typography>
              {data.email}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Address line 1:
              </Typography>
              {data.addressline1}
            </Grid>
            {data.addressline2 && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Address line 2:
                </Typography>
                {data.addressline2}
              </Grid>
            )}
            {data.addressline3 && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Address line 3:
                </Typography>
                {data.addressline3}
              </Grid>
            )}
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Police station:
              </Typography>
              {data.police_station}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                Postal code:
              </Typography>
              {data.postalcode}
            </Grid>
            {data.zone_name && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Zone name:
                </Typography>
                {data.zone_name}
              </Grid>
            )}
            {data.division_name && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Division name:
                </Typography>
                {data.division_name}
              </Grid>
            )}
            {data.district_name && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  District name:
                </Typography>
                {data.district_name}
              </Grid>
            )}
            {data.thana_name && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Thana name:
                </Typography>
                {data.thana_name}
              </Grid>
            )}
            {data.area_name && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Area name:
                </Typography>
                {data.area_name}
              </Grid>
            )}
          </Grid>
        </CardContent>
      ) : (
        <PageLoader />
      )}
      <>
        <ModalsWrapper
          modalData={{ title: 'View document' }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default CardCompanyInformation;
